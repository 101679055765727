.apexcharts-tooltip {
    border-radius: 8px !important;
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0 0 8px 0 rgba(113, 117, 126, 0.04) !important;
}

.tooltip_container {
    width: 275px;
    border-radius: 8px;
    border: 1px solid #f0f2f4;
    padding: 14px 14px 16px;
    overflow: hidden;
}
.tooltip_container .tooltip_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(240, 242, 244, 1);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.tooltip_container .tooltip_head span {
    font-size: 13px;
    font-weight: 600;
}

.tooltip_container .tooltip_head span:first-of-type {
    color: rgba(55, 59, 66, 1);
}
.tooltip_container .tooltip_head span:last-of-type {
    color: rgba(10, 152, 232, 1);
}
.tooltip_container p {
    font-size: 13px;
    line-height: 1.4;
    color: rgba(98, 105, 117, 1);
    white-space: pre-wrap;
}

.apexcharts-xaxis .apexcharts-text tspan {
    font-size: 12px;
    font-family: 'Pretendard';
}

.apexcharts-yaxis .apexcharts-text tspan {
    font-size: 10px;
    font-family: 'Pretendard';
}
